<template>
  <div class="public_navmain" style="background-color: transparent">
    <div class="boxcenter">
      <div
        class="public_navmain_left"
        style="background-color: #fff; margin-right: 20px; border-radius: 6px"
      >
        <div class="public_navmain_top">
          <!--        <el-avatar icon="el-icon-user-solid" :size="91"></el-avatar>-->
          <!-- <img src="../assets/morenimg.png" alt="" srcset="" /> -->
          <div class="suerbox">
            <img
              class="userinfostuAvatar"
              :src="
                userinfo.stuAvatar
                  ? $imgBaseUrl + userinfo.stuAvatar
                  : require('@/assets/morenimg.png')
              "
              alt="加载失败"
              srcset=""
            />
            <div class="userinfobox">
              <p style="font-size: 16px">{{ userinfo.name }}</p>
              <!-- <p style="font-size: 16px">{{ userinfo.companyName }}</p> -->
              <p
                class="companyName"
                :title="userinfo.companyName"
                style="font-size: 16px"
              >
                {{ userinfo.companyName }}
                <!-- 甘肃建投数字科技股份有限公司 -->
                <!-- <el-tooltip
                  content="甘肃建投数字科技股份有限公司"
                  placement="right"
                >
                  <span>甘肃建投数字科技股份有限公司</span>
                </el-tooltip> -->
              </p>
            </div>
          </div>
        </div>
        <el-divider></el-divider>

        <!-- <div class="public_navmain_center">账户管理</div> -->
        <div class="public_navmain_bottom">
          <div
            :class="currentIndex == item.type ? 'activebg' : 'noactivebg'"
            v-for="(item, index) in navlist"
            :key="index + 'b'"
            @click="navbg(index, item)"
          >
            <i :class="item.icon" style="margin-left: 20px"></i>
            <p style="font-size: 16px">{{ item.title }}</p>
            <el-icon class="el-icon-arrow-right"></el-icon>
          </div>
        </div>
      </div>
      <div
        class="public_navmain_right"
        style="background-color: #fff; border-radius: 6px; padding: 20px"
      >
        <div
          class="public_navmain_right_top"
          style="margin-left: 10px"
          v-if="showtitle != '个人信息'"
        >
          <p>{{ showtitle }}</p>
        </div>
        <!--     在线直播-->
        <div
          class="public_navmain_right_bottom"
          v-show="isshowContent == 'zxzb'"
          style="margin-left: 10px"
        >
          <div class="wdkc zxzbbox" v-if="liveList.length != 0">
            <div
              class="livebox"
              v-for="(item, index) in liveList"
              :key="index + 'c'"
              v-if="item.isShow == 1"
              @click="liveNow(item)"
            >
              <div class="innerbox">
                <img :src="$imgBaseUrl + item.photoUrl" alt="" srcset="" />
                <div class="innerboxfoot">
                  <p class="classTitle">{{ item.windowName }}</p>
                  <p class="classPerson">主讲人：{{ item.person }}</p>
                  <p class="classPerson">开讲时间：{{ item.beginTime }}</p>
                  <div class="classIntor">
                    简介:
                    {{ item.introduce }}
                  </div>
                </div>
              </div>
            </div>

            <el-empty
              v-show="
                liveList.filter((item) => item.isShow == 1).length == 0 ||
                liveList.length == 0
              "
              style="width: 100%"
              description="暂无直播数据"
              :image-size="395"
              :image="require('../assets/zanwu.png')"
            ></el-empty>
          </div>
          <el-pagination
            v-if="zxzbtotal > 0"
            @current-change="handleCurrentChangekchg"
            :current-page.sync="currentPagekchg"
            :page-size="8"
            layout="total, prev, pager, next"
            :total="zxzbtotal"
          >
          </el-pagination>
          <!-- liveList.every((item) => item.isShow == 0) ||  -->
        </div>
        <!--课程回顾 || 在线学习-->
        <div
          class="public_navmain_right_bottom"
          v-show="isshowContent == 'zxxx'"
          style="margin-left: 10px; position: relative"
        >
          <!-- <div class="wdkc zxzbbox">
            <div
              class="livebox"
              v-for="(item, index) in liveList"
              :key="index"
              v-if="item.isShow != 1"
              @click="liveback(item)"
            >
              <div class="innerbox">
                <img :src="$imgBaseUrl + item.photoUrl" alt="" srcset="" />
                <div class="innerboxfoot">
                  <p class="classTitle">{{ item.windowName }}</p>
                  <p class="classPerson">主讲人：{{ item.person }}</p>
                  <div class="classIntor">
                    简介:
                    {{
                      item.introduce
                    }}
                  </div>
                </div>
              </div>
            </div>
            <el-empty
              v-show="
                liveList.filter((item) => item.isShow != 1).length == 0 ||
                liveList.length == 0
              "
              style="width: 100%"
              description="暂无直播数据"
              :image-size="395"
              :image="require('../assets/zanwu.png')"
            ></el-empty>
          </div> -->
          <!-- 未观看 -->
          <!-- <div class="studybox">
            <img class="studyimg" src="../assets/zanwu.png" alt="" />
            <div class="studycontent">
              <div class="classtitle">
                《习近平关于坚持以人民为中心重要论述解读》
              </div>
              <el-form ref="studyform" label-width="80px">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="讲师:"> 张三 </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="时长:"> 60:00 </el-form-item>
                  </el-col>
                </el-row>

                <div style="display: flex; width: 300px; height: 30px">
                  <div class="prseetext">进度:</div>
                  <div style="width: 228px; height: 30px">
                    <el-progress :percentage="0"></el-progress>
                  </div>
                </div>
              </el-form>
            </div>
            <div
              class="studychapter"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <span style="font-size: 16px; color: #606266">未学习</span>
            </div>
            <div class="studytutton">
              <el-button class="gostudy" type="danger" @click="gostudyview"
                >立即学习</el-button
              >
            </div>
          </div> -->
          <!-- 继续观看 -->
          <!-- <div class="studybox">
            <img class="studyimg" src="../assets/zanwu.png" alt="" />
            <div class="studycontent">
              <div class="classtitle">
                《习近平关于坚持以人民为中心重要论述解读》
              </div>
              <el-form ref="studyform" label-width="80px">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="讲师:"> 张三 </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="时长:"> 60:00 </el-form-item>
                  </el-col>
                </el-row>

                <div style="display: flex; width: 300px; height: 30px">
                  <div class="prseetext">进度:</div>
                  <div style="width: 228px; height: 30px">
                    <el-progress :percentage="50"></el-progress>
                  </div>
                </div>
              </el-form>
            </div>
            <div class="studychapter">
              <img
                :src="require('../assets/uncompleted.png')"
                style="width: 100%; height: 100%"
                alt=""
              />
            </div>
            <div class="studytutton">
              <el-button class="gostudy" type="primary" @click="gostudyview"
                >继续学习</el-button
              >
            </div>
          </div> -->
          <!-- 重新学习 -->
          <template v-for="(item, index) in liveList">
            <!-- 完成 -->
            <div class="studybox" v-if="item.degreeStatus == 2">
              <el-popover placement="right" trigger="hover" width="220">
                <el-image
                  slot="reference"
                  style="width: 250px; border-radius: 12px"
                  :src="
                    item.photoUrl
                      ? $imgBaseUrl + item.photoUrl
                      : require('@/assets/zanwu.png')
                  "
                  :alt="$imgBaseUrl + item.photoUrl"
                ></el-image>
                <el-image
                  style="width: 600px"
                  :src="$imgBaseUrl + item.photoUrl"
                ></el-image>
              </el-popover>
              <!-- <img
                class="studyimg" 
                :src="
                  item.photoUrl
                    ? $imgBaseUrl + item.photoUrl
                    : require('@/assets/zanwu.png')
                "
                alt=""
              /> -->
              <div class="studycontent">
                <div class="classtitle">
                  {{ item.windowName }}
                </div>
                <el-form ref="studyform" label-width="80px">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="讲师:">
                        {{ item.person }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="时长:">
                        {{ gettimes(item.classTimes) }}
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <div style="display: flex; width: 300px; height: 30px">
                    <div class="prseetext">进度:</div>
                    <div style="width: 228px; height: 30px">
                      <el-progress
                        :percentage="100"
                        status="success"
                      ></el-progress>
                    </div>
                  </div>
                </el-form>
              </div>
              <div class="studychapter">
                <img
                  :src="require('../assets/watchover.png')"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </div>
              <div class="studytutton">
                <el-button
                  class="gostudy"
                  type="success"
                  @click="gostudyview(item)"
                  >重新学习</el-button
                >
              </div>
            </div>

            <!-- 继续观看 -->
            <div class="studybox" v-if="item.degreeStatus == 1">
              <el-popover placement="right" trigger="hover" width="220">
                <el-image
                  slot="reference"
                  style="width: 250px; border-radius: 12px"
                  :src="
                    item.photoUrl
                      ? $imgBaseUrl + item.photoUrl
                      : require('@/assets/zanwu.png')
                  "
                  :alt="$imgBaseUrl + item.photoUrl"
                ></el-image>
                <el-image
                  style="width: 600px"
                  :src="$imgBaseUrl + item.photoUrl"
                ></el-image>
              </el-popover>

              <!-- <el-popover placement="top-start" trigger="hover" style="width: 240px; height: 240px">
                <div style="width: 240px; height: 240px">
                  <img
                    class="studyimg"
                    :src="
                      item.photoUrl
                        ? $imgBaseUrl + item.photoUrl
                        : require('@/assets/zanwu.png')
                    "
                    alt=""
                  />
                </div>
              </el-popover> -->

              <!-- <img
                class="studyimg"
                :src="
                  item.photoUrl
                    ? $imgBaseUrl + item.photoUrl
                    : require('@/assets/zanwu.png')
                "
                alt=""
              /> -->
              <div class="studycontent">
                <div class="classtitle">
                  {{ item.windowName }}
                </div>
                <el-form ref="studyform" label-width="80px">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="讲师:">
                        {{ item.person }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="时长:">
                        {{ gettimes(item.classTimes) }}
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <div style="display: flex; width: 300px; height: 30px">
                    <div class="prseetext">进度:</div>
                    <div style="width: 228px; height: 30px">
                      <el-progress
                        :percentage="Math.floor(item.degree * 100)"
                      ></el-progress>
                    </div>
                  </div>
                </el-form>
              </div>
              <div class="studychapter">
                <img
                  :src="require('../assets/uncompleted.png')"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </div>
              <div class="studytutton">
                <el-button
                  class="gostudy"
                  type="primary"
                  @click="gostudyview(item)"
                  >继续学习</el-button
                >
              </div>
            </div>
            <!-- 未观看 -->
            <div class="studybox" v-if="item.degreeStatus == 0">
              <el-popover placement="right" trigger="hover" width="220">
                <el-image
                  slot="reference"
                  style="width: 250px; border-radius: 12px"
                  :src="
                    item.photoUrl
                      ? $imgBaseUrl + item.photoUrl
                      : require('@/assets/zanwu.png')
                  "
                  :alt="$imgBaseUrl + item.photoUrl"
                ></el-image>
                <el-image
                  style="width: 600px"
                  :src="$imgBaseUrl + item.photoUrl"
                ></el-image>
              </el-popover>
              <!-- <img
                class="studyimg"
                :src="
                  item.photoUrl
                    ? $imgBaseUrl + item.photoUrl
                    : require('@/assets/zanwu.png')
                "
                alt=""
              /> -->
              <div class="studycontent">
                <div class="classtitle">
                  {{ item.windowName }}
                </div>
                <el-form ref="studyform" label-width="80px">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="讲师:">
                        {{ item.person }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="时长:">
                        {{ gettimes(item.classTimes) }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <div style="display: flex; width: 300px; height: 30px">
                    <div class="prseetext">进度:</div>
                    <div style="width: 228px; height: 30px">
                      <el-progress :percentage="0"></el-progress>
                    </div>
                  </div>
                </el-form>
              </div>
              <div
                class="studychapter"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <span style="font-size: 15px; color: #f56c6c">未学习</span>
              </div>
              <div class="studytutton">
                <el-button
                  class="gostudy"
                  type="danger"
                  @click="gostudyview(item)"
                  >立即学习</el-button
                >
              </div>
            </div>
          </template>
          <el-empty
            v-show="
              liveList.filter((item) => item.isShow != 1).length == 0 ||
              liveList.length == 0
            "
            description="暂无直播数据"
            :image-size="395"
            :image="require('../assets/zanwu.png')"
          ></el-empty>
          <div class="block">
            <!-- 分页 -->
            <el-pagination
              style="position: absolute; bottom: 0; right: 0"
              @current-change="handleCurrentChangekchg"
              :current-page.sync="currentPagekchg"
              :page-size="livesQuery.pageSize"
              layout="total, prev, pager, next"
              :total="kchgtotal"
            >
            </el-pagination>
          </div>
          <!-- <div class="wdkc zxzbbox">
          <div
            class="wdkc_list"
            v-for="(item, index) in liveList"
            :key="index + 'c'"
            v-if="item.isShow != 1"
            @click="liveback(item)"
          >
            <div class="wdkc_list_top">
              <p>主讲人：{{ item.person }}</p>
            </div>
            <div class="wdkc_list_bottom">
              <div class="wdkc_list_bottom_left">
                <img :src="$imgBaseUrl + item.photoUrl" alt="" srcset="" />
              </div>
              <div class="wdkc_list_bottom_right">
                <p>{{ item.windowName }}</p>
                <p>{{ item.introduce }}</p>
              </div>
            </div>
          </div>
        </div> -->
          <!-- liveList.every((item) => item.isShow == 0) ||  -->
          <!-- <el-empty
          v-show="
            liveList.every((item) => item.isShow == 0) || liveList.length == 0
          "
          description="暂无直播数据"
          :image-size="395"
          :image="require('../assets/zanwu.png')"
        ></el-empty> -->
        </div>

        <!--      个人信息-->
        <div
          class="public_navmain_right_bottom grxx"
          v-show="isshowContent == 'grxx'"
        >
          <!-- <el-form ref="form" label-width="80px">
            <el-form-item label="姓名">
              {{ userinfo.name }}
            </el-form-item>
            <el-form-item label="性别">
              {{
                userinfo.sex == null ? '未知' : userinfo.sex == 0 ? '男' : '女'
              }}
            </el-form-item>
            <el-form-item label="身份证号">
              {{ userinfo.idNumber }}
            </el-form-item>
            <el-form-item label="联系电话">
              {{ userinfo.phone }}
            </el-form-item>
            <el-form-item label="修改密码">
              <el-button type="primary" @click="onSubmit">立即创建</el-button>
              <el-button>取消</el-button>
            </el-form-item>
          </el-form> -->
          <el-descriptions :column="1" border title="用户信息">
            <el-descriptions-item label="用户姓名">
              {{ userinfo.name }}</el-descriptions-item
            >
            <el-descriptions-item label="身份证号">
              {{ userinfo.idNumber }}</el-descriptions-item
            >
            <el-descriptions-item label="登录账号">
              {{ userinfo.userName }}</el-descriptions-item
            >
            <el-descriptions-item label="联系电话">
              {{ userinfo.phone }}
            </el-descriptions-item>
            <el-descriptions-item label="所在单位">
              {{ userinfo.companyName }}
            </el-descriptions-item>
            <!-- <el-descriptions-item label="修改密码">
              <el-button type="primary" @click="changepass">点击修改</el-button>
            </el-descriptions-item> -->
          </el-descriptions>
          <el-descriptions
            :column="1"
            border
            title="个人信息修改"
            style="margin-top: 20px"
          >
            <el-descriptions-item label="信息维护">
              <el-button type="primary" @click="changeinfo">点击修改</el-button>
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions
            :column="1"
            border
            title="修改密码"
            style="margin-top: 20px"
          >
            <el-descriptions-item label="修改密码">
              <el-button type="primary" @click="changepass">点击修改</el-button>
            </el-descriptions-item>
          </el-descriptions>
          <!-- <div class="grxx_tag">
            <div class="grxx_name">姓名</div>
            <div class="grxx_main">{{ userinfo.name }}</div>
          </div>
          <div class="grxx_tag">
            <div class="grxx_name">性别</div>
            <div class="grxx_main">
              {{
                userinfo.sex == null ? '未知' : userinfo.sex == 0 ? '男' : '女'
              }}
            </div>
          </div>

          <div class="grxx_tag">
            <div class="grxx_name">出生日期</div>
            <div class="grxx_main">未知</div>
          </div>
          <div class="grxx_tag">
            <div class="grxx_name">身份证号</div>
            <div class="grxx_main">{{ userinfo.idNumber }}</div>
          </div>
          <div class="grxx_tag">
            <div class="grxx_name">联系电话</div>
            <div class="grxx_main">{{ userinfo.phone }}</div>
          </div> -->
        </div>
        <!--      在线考试-->
        <div
          class="public_navmain_right_bottom zxks"
          v-show="isshowContent == 'zxks'"
        >
          <div class="zxks_list" v-for="(item, index) in examList" :key="index">
            <div class="zxks_top">
              <div style="display: flex; align-items: center">
                <img
                  src="../assets/kaoshi.png"
                  alt=""
                  style="width: 18px; height: 18px"
                  srcset=""
                />
                <div class="tit">{{ item.examName }}</div>
              </div>
            </div>
            <div class="zxks_bottom">
              <span style="font-size: 14px"
                >考试时间：<span
                  >{{ item.beginTime }} 至 {{ item.endTime }}</span
                ></span
              >
            </div>
            <div class="exambox">
              <div
                v-for="(citem, cindex) in item.examRecordList"
                v-if="item.examRecordList.length > 0"
                style="text-align: right; width: 100%"
              >
                成绩：<span style="color: red; font-weight: bold">{{
                  citem.scope == -1 ? '暂无成绩' : citem.scope
                }}</span>
                分
                <span
                  v-if="
                    item.examRecordList.length > 0 &&
                    item.examRecordList[item.examRecordLis.length - 1].status ==
                      1
                  "
                  style="color: green; cursor: pointer; margin-left: 20px"
                  @click="examEvent(item)"
                >
                  继续考试
                </span>
              </div>
              <!-- <div v-if="item.examRecordList.length == 0">
             
              暂未考试
            </div> -->

              <div
                style="margin-left: 2em"
                v-show="item.examRecordList.length == 0"
              >
                待考试
              </div>
              <span
                v-if="
                  !item.examRecordList.length ||
                  (item.examRecordList[item.examRecordLis.length - 1].status ==
                    2 &&
                    item.examRecordList.length < item.examTimes)
                "
                style="color: red; cursor: pointer"
                @click="examEvent(item)"
              >
                请从此入口进入开始考试
              </span>
            </div>
          </div>
          <el-empty
            v-show="!examList.length"
            description="暂无考试"
            :image-size="395"
            :image="require('../assets/zanwu.png')"
          ></el-empty>
        </div>
        <!--   证书下载-->
        <div
          class="public_navmain_right_bottom zxks zxxz"
          v-show="isshowContent == 'zsxz'"
        >
          <div v-for="item in certificateList">
            <div class="zxks_list">
              <div class="zxks_top">
                <div class="zxxz_con">
                  <img src="../assets/kaoshi.png" alt="" srcset="" />
                  <div class="tit">{{ item.examName }}</div>
                </div>
                <div>
                  成绩：<span style="color: red">{{ item.scope }}</span> 分
                </div>
              </div>
              <div class="zxks_bottom">
                <!-- <span>证书下载功能2023年2月11日开放</span> -->

                <p style="color: red">
                  备注：纸质监督员证书领取方式，时间另行通知
                </p>

                <div
                  class="sczs"
                  style="cursor: pointer; color: green"
                  @click="downloadCertificate(item)"
                >
                  下载网络培训证书
                </div>
              </div>
            </div>
          </div>
          <el-empty
            style="width: 100%; height: 100%"
            v-show="certificateList.length == 0"
            description="暂无课程数据"
            :image-size="395"
            :image="require('../assets/zanwu.png')"
          ></el-empty>
        </div>

        <!--      我的课程-->
        <div
          class="public_navmain_right_bottom zxks zxxz"
          v-show="isshowContent == 'wdkc'"
        >
          <div class="wdkc">
            <div
              class="wdkc_list"
              @click="courseDetail(item)"
              v-for="(item, index) in courseList"
              :key="index + 'a'"
            >
              <div class="wdkc_list_top">
                <p>{{ item.createTime }}</p>
                <p>必修课总数：{{ item.requiredCount }}</p>
                <p>选修课总数：{{ item.electiveCount }}</p>
              </div>
              <div class="wdkc_list_bottom">
                <div class="wdkc_list_bottom_left">
                  <img
                    :src="$imgBaseUrl + item.imgUrl"
                    alt=""
                    srcset="../assets/kcimg.png"
                  />
                </div>
                <div class="wdkc_list_bottom_right">
                  <p>{{ item.subjectName }}</p>
                  <p>{{ item.abstract }}</p>
                  <p>总课时：{{ item.totalTime }}</p>
                </div>
              </div>
            </div>
            <el-empty
              v-show="courseList.length == 0"
              description="暂无课程数据"
              :image-size="395"
              :image="require('../assets/zanwu.png')"
            ></el-empty>
          </div>
        </div>
        <div style="text-align: right"></div>
      </div>
      <el-dialog
        title="私密直播，请输入密码"
        :visible.sync="dialogTableVisible"
      >
        <el-input
          placeholder="请输入密码"
          v-model="inputing"
          show-password
        ></el-input>
        <div style="margin-top: 40px">
          <el-button type="primary" @click="passwording">确定</el-button>
        </div>
      </el-dialog>
      <el-dialog
        @close="closedialogvidoes"
        v-if="kchgishow"
        :title="kchgishowtitle"
        top="10vh"
        :visible.sync="kchgishow"
        width="60%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <vue-aliplayer-v2
          :source="source"
          ref="VueAliplayerV2"
          :options="options"
          @ready="handleReady"
          @ended="handleEnded"
          @play="play"
        />
      </el-dialog>
      <el-dialog
        title="修改密码"
        :visible.sync="changebox"
        width="30%"
        :before-close="handleClose"
      >
        <div>
          <el-form
            :model="ruleForm"
            status-icon
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="原密码" prop="Oldpassword">
              <el-input v-model.number="ruleForm.Oldpassword"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="pass">
              <el-input
                type="password"
                v-model="ruleForm.pass"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <el-input
                type="password"
                v-model="ruleForm.checkPass"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >提交</el-button
              >
              <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
    <el-dialog
      title="个人信息修改"
      :visible.sync="dialogVisibleinfo"
      width="30%"
      :before-close="handleCloseinfo"
      style="text-align: left; border-radius: 12px"
    >
      <el-form
        label-width="80px"
        :model="formInline"
        class="demo-form-inline"
        ref="formInline"
      >
        <el-form-item label="姓名">
          <el-input v-model="formInline.name" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item label="电话号码">
          <el-input
            v-model="formInline.phone"
            placeholder="电话号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="工作单位">
          <el-input
            v-model="formInline.companyName"
            placeholder="工作单位"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input
            v-model="formInline.idNumber"
            placeholder="身份证号"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="subinfo(1)">取 消</el-button>
        <el-button type="primary" @click="subinfo(2)">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="个人信息"
      :visible.sync="infobox"
      width="30%"
      style="text-align: left; border-radius: 12px"
    >
      <el-form
        label-width="80px"
        :model="formInline"
        class="demo-form-inline"
        ref="formInline"
      >
        <el-form-item label="姓名">
          <el-input
            :disabled="true"
            v-model="userinfo.name"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="电话号码">
          <el-input
            :disabled="true"
            v-model="userinfo.phone"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="工作单位">
          <el-input
            :disabled="true"
            v-model="userinfo.companyName"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input
            :disabled="true"
            v-model="userinfo.idNumber"
            placeholder=""
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="gochange">去修改</el-button>
        <el-button type="primary" @click="goexam">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="请选择考试类型"
      top="30vh"
      :visible.sync="kslx"
      width="20%"
      :close-on-press-escape="false"
      @close="subexamtype(1)"
    >
      <el-radio-group v-model="examtype" v-removeAriaHidden>
        <div style="display: flex; flex-direction: column; text-align: left">
          <el-radio
            v-for="item in examtypelist"
            :label="item.id"
            style="margin: 5px"
            >{{ item.subjectName }}</el-radio
          >
        </div>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="subexamtype(1)">取 消</el-button>
        <el-button size="mini" type="primary" @click="subexamtype(2)"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

import {
  getCourseList,
  getDicts,
  getInfo,
  getLives,
  logout,
  getExam,
  queryCertificate,
  downloadCertificate,
  createPaperByRule,
  role,
  getPlayCode,
  watchjoin,
  watchexit,
  doQualification,
  updatePwd,
  fixstudent,
  getnoPageList,
} from '@/utils/api'
import { getTime } from '@/utils/utils.js'
export default {
  name: 'publicnavmain',
  dicts: ['sys_user_sex', 'stu_nation'],
  components: { ElImageViewer },
  data() {
    var checkAge = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        this.ischeck = false

        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.pass) {
        this.ischeck = false

        callback(new Error('两次输入密码不一致!'))
      } else {
        this.ischeck = true
        callback()
      }
    }
    return {
      examtypelist: [],
      examtype: null,
      kslx: false,
      examinfo: {},
      infobox: false,
      formInline: {
        name: '',
        phone: '',
        companyName: '',
        idNumber: '',
      },
      formInlineCN: {
        name: '姓名',
        phone: '电话号码',
        companyName: '工作单位',
        idNumber: '身份证号',
      },
      dialogVisibleinfo: false,
      isrule: false,
      ischeck: false,
      ruleForm: {
        pass: '',
        checkPass: '',
        Oldpassword: '',
      },
      rules: {
        // pass: [{ validator: validatePass, trigger: 'blur' }],
        pass: [
          {
            required: true,
            message: '请输入密码',
            transform: (value) => value,
            trigger: 'blur',
          },
          {
            type: 'string',
            message: '请输入不包含空格的字符',
            trigger: 'blur',
            transform(value) {
              if (value && value.indexOf(' ') === -1) {
                return value
              } else {
                return false
              }
            },
          },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              var passwordreg = /^\S{6,16}$/
              if (!passwordreg.test(value)) {
                this.isrule = false
                callback(new Error('密码必须由请输入6-16位组成'))
              } else {
                this.isrule = true
                callback()
              }
            },
          },
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur', required: true },
        ],
        Oldpassword: [{ validator: checkAge, trigger: 'blur', required: true }],
      },
      changebox: false,
      currentPagekchg: 1,
      kchgtotal: 0,
      zxzbtotal: 0,
      currentPage: 1,
      kchgishowtitle: '课程回顾',
      expiretime: null, //记录时间
      idvcad: null,
      source: null,
      options: {
        rePlay: false, //自动重新播放
        autoplay: true, //自动播放
        source: '',
        vid: '',
        playauth: '',
        isLive: false, //切换为直播流的时候必填
        width: '100%',
        height: '555px',
        showBarTime: false,
        // format: 'm3u8', //切换为直播流的时候必填
        //说明：功能组件布局配置，不传该字段使用默认布局。传 false 隐藏所有功能组件，请参照皮肤定制。
        skinLayout: [
          {
            name: 'bigPlayButton',
            align: 'blabs',
            x: 30,
            y: 80,
          },
          {
            name: 'H5Loading',
            align: 'cc',
          },
          {
            name: 'errorDisplay',
            align: 'tlabs',
            x: 0,
            y: 0,
          },
          {
            name: 'infoDisplay',
          },
          {
            name: 'tooltip',
            align: 'blabs',
            x: 0,
            y: 56,
          },
          {
            name: 'thumbnail',
          },
          {
            name: 'controlBar',
            align: 'blabs',
            x: 0,
            y: 0,
            children: [
              // {
              //   name: 'progress',
              //   align: 'blabs',
              //   x: 0,
              //   y: 44,
              // },
              // {
              //   name: 'playButton',
              //   align: 'tl',
              //   x: 15,
              //   y: 12,
              // },
              // {
              //   name: 'timeDisplay',
              //   align: 'tl',
              //   x: 10,
              //   y: 7,
              // },
              {
                name: 'fullScreenButton',
                align: 'tr',
                x: 10,
                y: 12,
              },
              // {
              //   name: 'subtitle',
              //   align: 'tr',
              //   x: 15,
              //   y: 12,
              // },
              {
                name: 'volume',
                align: 'tr',
                x: 5,
                y: 10,
              },
            ],
          },
        ],
      },
      kchgishow: false,
      navlist: [
        // {
        //   title: '在线直播',
        //   type: 'zxzb',
        // },
        // {
        //   title: '课程回顾',
        //   type: 'kchg',
        // },
        {
          title: '在线学习',
          type: 'zxxx',
          icon: 'el-icon-reading',
        },
        {
          title: '在线考试',
          type: 'zxks',
          icon: 'el-icon-edit',
        },
        // {
        //   title: '我的课程',
        //   type: 'wdkc',
        // },
        {
          title: '证书下载',
          type: 'zsxz',
          icon: 'el-icon-medal-1',
        },
        {
          title: '个人信息',
          type: 'grxx',
          icon: 'el-icon-user',
        },
        {
          title: '退出登录',
          type: 'tcdl',
          icon: 'el-icon-switch-button',
        },
      ],
      certificateList: [],
      currentIndex: 'grxx',
      showtitle: '个人信息',
      isshowContent: '',
      userinfo: {},
      dictListquery: {
        dictType: 'stu_nation',
        dictValue: 1,
      },
      setNation: '',
      // 直播参数
      livesQuery: {
        pageSize: 4,
        pageNum: 1,
        status: '',
        is_ok: '2',
      },
      // 课程参数
      fcourseQuery: {
        // pageSize: 5,
        // pageNum: 1,
        stuId: '',
      },
      liveList: [],
      courseList: [],
      examList: [],
      dialogTableVisible: false,
      inputing: '',
      password: '',
      pullUrl: '',
    }
  },
  created() {
    this.$eventBus.$on('changeFunc', (item, title) => {
      // this.currentIndex = item
      // this.isshowContent = item
      // this.showtitle = title
      this.isshowContent = item
      this.currentIndex = item
      this.showtitle = title
      if (item == 'zxzb' || item == 'zxxx') {
        this.livesQuery.pageNum = 1
        this.currentPagekchg = 1
        this.lives()
      } else if (item == 'zsxz') {
        this.getCertificateList()
      } else if (item == 'zxks') {
        this.getExamList()
      }
    })
  },

  mounted() {
    this.$nextTick(() => {
      this.getInfoList()
      this.lives()
      this.showtitle = '在线学习'
      this.currentIndex = 'zxxx'
      this.isshowContent = 'zxxx'
      console.log('sessionStorage.active', sessionStorage.active)
      if (sessionStorage.active == 'zxxx') {
        this.livesQuery.pageNum = 1
        this.currentPagekchg = 1
        this.lives()
        this.showtitle = '在线学习'
        this.currentIndex = 'zxxx'
        this.isshowContent = 'zxxx'
      } else if (sessionStorage.active == 'zxks') {
        this.showtitle = '在线考试'
        this.currentIndex = 'zxks'
        this.isshowContent = 'zxks'
        this.getExamList()
      } else if (sessionStorage.active == 'zsxz') {
        this.showtitle = '证书下载'
        this.currentIndex = 'zsxz'
        this.isshowContent = 'zsxz'
        this.getCertificateList()
      }
      sessionStorage.removeItem('active')
    })

    // 监听事件
    //   个人信息

    // this.getCourse()
    // 获取证书接口
    // this.getCertificateList()
    // this.lives()
    // if (
    //   localStorage.getItem('isshowContent') != null &&
    //   localStorage.getItem('isshowContent') != undefined
    // ) {
    //   this.isshowContent = JSON.parse(
    //     localStorage.getItem('isshowContent')
    //   ).type
    //   this.currentIndex = JSON.parse(localStorage.getItem('isshowContent')).type
    //   this.showtitle = JSON.parse(localStorage.getItem('isshowContent')).title
    // }
    // this.isshowContent = sessionStorage.isshowContent
    // this.currentIndex = sessionStorage.currentIndex
    // this.showtitle = sessionStorage.showtitle
    // this.currentIndex = 'grxx'
    // this.isshowContent = 'grxx'
    // console.log('isshowContent', sessionStorage.isshowContent)
  },

  methods: {
    subexamtype(type) {
      if (type == 1) {
        this.kslx = false
        this.examtype = null
      } else if (type == 2) {
        this.kslx = false
        this.isshowContent = 'zxks'
        this.currentIndex = 'zxks'
        this.showtitle = '在线考试'
        this.getExamList(this.examtype)
      }
    },

    goexam() {
      this.$confirm('是否确认当前信息正确无误，即将开始考试？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then((res) => {
          if (
            this.userinfo.name == null ||
            this.userinfo.phone == null ||
            this.userinfo.companyName == null ||
            this.userinfo.idNumber == null
          ) {
            this.$confirm(
              '请完善您的个人信息--个人信息修改--点击修改',
              '提示',
              {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              }
            )
              .then(() => {
                this.infobox = false
                this.isshowContent = 'grxx'
                this.currentIndex = 'grxx'
                this.showtitle = '个人信息'
                return
              })
              .catch(() => {
                return
              })
          } else {
            if (this.examinfo.num == 1) {
              // 随机试卷
              if (this.examinfo.type == 2) {
                if (
                  this.examinfo.examRecordList.length == 0 ||
                  this.examinfo.examRecordList[0].status == 1
                ) {
                  doQualification().then((res) => {
                    if (res.code == 200) {
                      this.$router.push({
                        name: 'exam',
                        query: {
                          objId: this.examinfo.id,
                          examid: this.examinfo.type,
                          stuId: this.userinfo.id,
                        },
                      })
                    } else {
                      // this.$message({
                      //   type: 'warning',
                      //   message: res.msg,
                      // })
                    }
                  })
                } else if (this.examinfo.examRecordList[0].status == 2) {
                  this.$message({
                    type: 'warning',
                    message: '考试已完成,暂时无法考试!',
                  })
                }
              } else if (this.examinfo.type == 1) {
                this.$router.push({
                  name: 'exam',
                  query: {
                    objId: this.examinfo.id,
                    examid: this.examinfo.type,
                    stuId: this.userinfo.id,
                  },
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '时间未到,暂时无法考试!',
              })
            }
          }
        })
        .catch((r) => {})
    },
    gochange() {
      this.infobox = false
      this.isshowContent = 'grxx'
      this.currentIndex = 'grxx'
      this.showtitle = '个人信息'
    },
    handleCloseinfo() {
      this.subinfo(1)
    },
    restformInline() {
      this.formInline.name = ''
      this.formInline.phone = ''
      this.formInline.companyName = ''
      this.formInline.idNumber = ''
    },
    async subinfo(type) {
      if (type == 1) {
        this.restformInline()
        this.dialogVisibleinfo = false
      } else if (type == 2) {
        for (const key in this.formInline) {
          const element = this.formInline[key]
          if (element == null || element == '') {
            console.log('key', key, this.formInlineCN)
            this.$message({
              message: `${this.formInlineCN[key]}不能为空`,
              type: 'error',
            })
            return
          }
        }
        let obj = {
          ...this.formInline,
          id: localStorage.getItem('userId'),
        }
        const res = await fixstudent(obj)
        if (res.code == 200) {
          this.$message({
            message: '信息修改成功',
            type: 'success',
          })
          this.dialogVisibleinfo = false
          this.restformInline()

          this.getInfoList()
        }
      }
    },
    changeinfo() {
      this.formInline.name = this.userinfo.name
      this.formInline.idNumber = this.userinfo.idNumber
      this.formInline.userName = this.userinfo.userName
      this.formInline.phone = this.userinfo.phone
      this.formInline.companyName = this.userinfo.companyName
      this.dialogVisibleinfo = true
    },
    onSubmit() {
      console.log('submit!')
    },
    gettimes(value) {
      return getTime(value)
    },
    gostudyview(item) {
      sessionStorage.vidoesinfo = JSON.stringify(item)
      sessionStorage.usid = this.userinfo.id
      this.$router.push({
        path: 'study',
        query: {
          idvcad: item.idVarchar,
          playid: item.id,
        },
      })
    },
    async submitForm(formName) {
      const h = this.$createElement

      if (this.ruleForm.Oldpassword) {
        if (!this.isrule) {
          this.$message({
            message: '密码必须请由6-16位组成',
            type: 'error',
          })
          return
        }
        if (!this.ruleForm.pass) {
          this.$message({
            message: '请输入新密码！',
            type: 'error',
          })
          return
        } else if (!this.ruleForm.checkPass) {
          this.$message({
            message: '旧密码不能为空！',
            type: 'error',
          })
          return
        }
        if (!this.ischeck) {
          this.$message({
            message: '两次密码不一致',
            type: 'error',
          })
          return
        } else {
          const res = await updatePwd({
            oldPassword: this.ruleForm.Oldpassword,
            newPassword: this.ruleForm.pass,
          }).catch((err) => {
            return
          })
          console.log('this.ruleForm.', res)
          if (res && res.code == 200) {
            this.changebox = false
            this.resetForm()
            this.$alert(res.msg + '密码修改成功！', '提示', {
              confirmButtonText: '确定',
              type: 'success',
              showClose: false,
              closeOnPressEscape: false,
              closeOnClickModal: false,
              callback: () => {
                console.log(222)
                sessionStorage.clear()
                this.$router.replace('/login')
              },
            })
          } else {
            // this.$notify({
            //   title: '提示',
            //   type: 'error',
            //   message: h('i', { style: 'color: teal' }, res.msg),
            // })
          }
        }
      } else {
        this.$message({
          message: '请输入原密码！',
          type: 'error',
        })
      }

      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     alert('submit!')
      //   } else {
      //     console.log('error submit!!')
      //     return false
      //   }
      // })
    },
    resetForm(formName) {
      this.$refs.ruleForm.resetFields()
      console.log(this.ruleForm, '&&&&%%')
    },
    resetFields(formName) {
      console.log(formName, '2222&&&&%%')
    },
    handleClose(done) {
      this.changebox = false
      this.resetForm()
    },
    changepass() {
      this.changebox = true
      console.log(111)
    },
    handleCurrentChangekchg(val) {
      console.log(`当前页: ${val}`)
      this.livesQuery.pageNum = val
      console.log('this.livesQuery.pageNum', this.livesQuery.pageNum)
      this.lives()
    },
    initvideos() {
      let obj = {
        type: 1,
        videoId: this.idvcad,
        stuId: this.userinfo.id,
      }
      watchjoin(obj).then((res) => {
        if (res.code == 200) {
          this.expiretime = res.data.times
          if (res.data.status == 1) {
            this.options.skinLayout[this.options.skinLayout.length - 1][
              'children'
            ] = [
              {
                name: 'progress',
                align: 'blabs',
                x: 0,
                y: 44,
              },

              {
                name: 'fullScreenButton',
                align: 'tr',
                x: 10,
                y: 12,
              },

              {
                name: 'volume',
                align: 'tr',
                x: 5,
                y: 10,
              },
            ]
          }
        }
      })
    },
    // 关闭课程回顾
    closedialogvidoes(e) {
      let times = this.$refs.VueAliplayerV2.getCurrentTime()
      let obj = {
        type: 1,
        videoId: this.idvcad,
        stuId: this.userinfo.id,
        times,
      }
      watchexit(obj).then((res) => {})
    },

    // 获取视频
    async getvidoes(idvcad) {
      this.idvcad = idvcad
      getPlayCode(idvcad).then((res) => {
        this.options.playauth = res.msg
        this.options.vid = idvcad
        // this.options.hlsFrameChasing = true
      })
      // if (this.$route.query.obj) {
      //   let idvcad = this.$route.query.obj
      //   const expiretimeres = await getSecond(sessionStorage.btime)
      //   if (expiretimeres.code == 200) {
      //     console.log('expiretimeres', expiretimeres)
      //     this.expiretime = Number(expiretimeres.data)
      //     getPlayCode(idvcad).then((res) => {
      //       this.options.playauth = res.msg
      //       this.options.vid = this.$route.query.obj
      //       // this.options.hlsFrameChasing = true
      //     })
      //   }
      //   // this.source = this.$route.query.obj
      // } else {
      //   // 则直播
      //   // this.options.source =
      //   //   'https://live.gsjtsz.com/app/app.m3u8?auth_key=3407759862-0-0-fd983d84837773df8090b40bb2488909'
      //   // this.source =
      //   //   'https://live.gsjtsz.com/app/app.m3u8?auth_key=3407759862-0-0-fd983d84837773df8090b40bb2488909'
      //   this.options.isLive = true
      //   this.options.format = 'm3u8'
      //   this.options.controlBar = false
      //   if (sessionStorage.livepath) {
      //     this.options.source = sessionStorage.livepath
      //   }
      //   // this.options.loadingTimeOut = 3600
      //   // this.play()
      //   // console.log(sessionStorage.livepath)
      // }
    },
    // 点击课程回顾
    liveback(item) {
      this.kchgishow = true
      console.log(item)
      this.kchgishowtitle = `[ ${item.windowName} ]` + '课程回顾'
      this.getvidoes(item.idVarchar)
      this.initvideos()
    },
    play() {
      // this.$refs.VueAliplayerV2.seek(Number(this.expiretime))
      // getSecond(sessionStorage.btime).then((expiretimeres) => {
      //   if (expiretimeres.code == 200) {
      //     this.expiretime = expiretimeres.data
      //     let seektime =
      //       Number(this.getvioesDuration()) - Number(this.expiretime)
      //     if (seektime < 0) {
      //       this.endmessage()
      //     } else {
      //       this.$refs.VueAliplayerV2.seek(Number(this.expiretime))
      //       this.$refs.VueAliplayerV2.play()
      //     }
      //   }
      // })
    },
    // 播放完完成时候

    handleEnded(e) {
      // sessionStorage.currtime = 0
      // this.endmessage()
      let obj = {
        type: 1,
        videoId: this.idvcad,
        stuId: this.userinfo.id,
        over: 1,
      }
      watchexit(obj).then((res) => {})
    },
    // 准备就绪时候
    handleReady(e) {
      this.$refs.VueAliplayerV2.seek(Number(this.expiretime))
      // let seektime = Number(this.getvioesDuration()) - this.expiretime
      // console.log(
      //   '这是总时长：',
      //   this.getvioesDuration(),
      //   '这是获取时长：',
      //   this.expiretime
      // )
      // if (seektime < 0) {
      //   // this.endmessage()
      // } else {
      //   this.$refs.VueAliplayerV2.seek(Number(this.expiretime))
      //   this.$refs.VueAliplayerV2.play()
      // }
    },
    async createPaper(data) {
      return await createPaperByRule(data)
    },
    async creatrole(id) {
      return await role(id)
    },
    // 查询证书
    getCertificateList() {
      let userid = localStorage.getItem('userId')
      queryCertificate(userid).then((res) => {
        this.certificateList = res.data
      })
    },
    // 下载证书
    async downloadCertificate(item) {
      // let path = item.certificate
      console.log(item)
      // let data = path.substring(
      //   path.lastIndexOf('/') + 1,
      //   path.lastIndexOf('.')
      // )
      // let data=item.
      // var url =
      //   this.$imgBaseUrl +
      //   '/prod-api/exam/exam/downloadCertificate?certificate=' +
      //   data
      // window.open(url)
      // var imgUrl = process.env.VUE_APP_API_BASE_URL + item.certificate
      // window.open(url)
      const imgUrl = process.env.VUE_APP_API_BASE_URLs + item.certificate
      const response = await fetch(imgUrl)
      const blob = await response.blob()
      // 创建下载链接
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = 'image.png'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      // 释放 Blob URL
      window.URL.revokeObjectURL(url)
    },
    async navbg(index, item) {
      if (item.type == 'tcdl') {
        this.$confirm('退出当前账号，是否继续？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
          .then((res) => {
            logout().then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: res.msg,
                  type: 'success',
                })
                localStorage.clear()
                this.$router.push('/login')
              }
            })
          })
          .catch((r) => {})
      } else {
        if (item.type == 'zxzb' || item.type == 'zxxx') {
          this.isshowContent = item.type
          this.currentIndex = item.type
          this.showtitle = item.title
          this.livesQuery.pageNum = 1
          this.currentPagekchg = 1
          this.lives()
        } else if (item.type == 'zsxz') {
          this.isshowContent = item.type
          this.currentIndex = item.type
          this.showtitle = item.title
          this.getCertificateList()
        } else if (item.type == 'grxx') {
          this.isshowContent = item.type
          this.currentIndex = item.type
          this.showtitle = item.title
          // this.getExamList()
        } else if (item.type == 'zxks') {
          // 点击考试获取类型
          this.kslx = true
          const res = await getnoPageList()
          if (res.code == 200) {
            this.examtypelist = res.data
          }
          // this.getExamList()
        }
      }

      // 退出登录
      // if (item.type == 'zxzb' || item.type == 'kchg') {
      //   // console.log(222111)
      //   this.livesQuery.pageNum = 1
      //   this.currentPagekchg = 1
      //   this.lives()
      // }
      // if (item.type == 'tcdl') {
      //   this.$confirm('退出当前账号，是否继续？', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //   })
      //     .then((res) => {
      //       logout().then((res) => {
      //         if (res.code == 200) {
      //           this.$message({
      //             message: res.msg,
      //             type: 'success',
      //           })
      //           localStorage.clear()
      //           this.$router.push('/login')
      //         }
      //       })
      //     })
      //     .catch((r) => {})
      // } else {
      //   this.currentIndex = item.type
      //   localStorage.setItem('isshowContent', JSON.stringify(item))
      //   this.isshowContent = JSON.parse(
      //     localStorage.getItem('isshowContent')
      //   ).type
      //   this.showtitle = JSON.parse(localStorage.getItem('isshowContent')).title
      //   // console.log('this.isshowContent', this.isshowContent)
      // }
      // sessionStorage.isshowContent = item.type
      // sessionStorage.currentIndex = item.type
      // sessionStorage.showtitle = item.title
      // console.log(sessionStorage.currentIndex)
    },
    // 字典
    dict(dictType, dictValue) {
      getDicts({
        dictType: dictType,
        dictValue: dictValue,
      }).then((res) => {
        if (res.code == 200) {
          this.setNation = res.data
        }
      })
    },
    courseDetail(item) {
      this.$router.push({ name: 'coursestudy', params: { item: item } })
      localStorage.setItem('courseid', item.id)
    },
    // initlist() {
    //   //在线直播
    //   //   课程
    //   // this.getCourse()
    //   // //  考试
    //   // this.getExamList()
    // },
    // 考试列表
    getExamList(subid) {
      // console.log('刷了新@@@@@')
      let userid = localStorage.getItem('userId')
      getExam(userid, subid).then((res) => {
        if (res.code == 200) {
          this.examList = res.data
          for (let index = 0; index < this.examList.length; index++) {
            let element = this.examList[index]
            element.examRecordLis = element.examRecordList.reverse()
          }
        }
      })
    },
    // 考试详情
    examEvent(item) {
      this.infobox = true
      // num表示在不在考试时间范围,1代表在
      this.examinfo = item
      // if (item.num == 1) {
      //   // 随机试卷
      //   if (item.type == 2) {
      //     if (
      //       item.examRecordList.length == 0 ||
      //       item.examRecordList[0].status == 1
      //     ) {
      //       doQualification().then((res) => {
      //         if (res.code == 200) {
      //           this.$router.push({
      //             name: 'exam',
      //             query: {
      //               objId: item.id,
      //               examid: item.type,
      //               stuId: this.userinfo.id,
      //             },
      //           })
      //         } else {
      //           // this.$message({
      //           //   type: 'warning',
      //           //   message: res.msg,
      //           // })
      //         }
      //       })
      //     } else if (item.examRecordList[0].status == 2) {
      //       this.$message({
      //         type: 'warning',
      //         message: '考试已完成,暂时无法考试!',
      //       })
      //     }
      //   } else if (item.type == 1) {
      //     this.$router.push({
      //       name: 'exam',
      //       query: {
      //         objId: item.id,
      //         examid: item.type,
      //         stuId: this.userinfo.id,
      //       },
      //     })
      //   }
      // } else {
      //   this.$message({
      //     type: 'warning',
      //     message: '时间未到,暂时无法考试!',
      //   })
      // }

      // 固定试卷

      // if (item.type == 1) {
      //   if (sopeid) {
      //     // sessionStorage.sopeid = sopeid
      //     this.$router.push({
      //       name: 'exam',
      //       query: {
      //         objId: item.id,
      //         examid: item.type,
      //         stuId: this.userinfo.id,
      //       },
      //     })
      //     sessionStorage.objId = item.id
      //     return
      //   } else {
      //     if (item.num == 1) {
      //       if (item.examNum < item.examTimes) {
      //         this.$router.push({
      //           name: 'exam',
      //           query: {
      //             objId: item.id,
      //             examid: item.type,
      //             stuId: this.userinfo.id,
      //           },
      //         })
      //         sessionStorage.objId = item.id
      //       } else {
      //         this.$message({
      //           type: 'warning',
      //           message: '考试次数已用完,暂时无法考试!',
      //         })
      //       }
      //     } else {
      //       this.$message({
      //         type: 'warning',
      //         message: '时间未到,暂时无法考试!',
      //       })
      //     }
      //   }
      // } else if (item.type == 2) {
      //   // 随机试卷
      //   if (sopeid) {
      //     this.$router.push({
      //       name: 'exam',
      //       query: {
      //         objId: item.id,
      //         examid: item.type,
      //         stuId: this.userinfo.id,
      //       },
      //     })
      //     sessionStorage.objId = item.id
      //   } else {
      //     if (item.num == 1) {
      //       if (item.examNum < item.examTimes) {
      //         this.$router.push({
      //           name: 'exam',
      //           query: {
      //             objId: item.id,
      //             examid: item.type,
      //             stuId: this.userinfo.id,
      //           },
      //         })
      //         sessionStorage.objId = item.id
      //       } else {
      //         this.$message({
      //           type: 'warning',
      //           message: '考试次数已用完,暂时无法考试!',
      //         })
      //       }
      //     } else {
      //       this.$message({
      //         type: 'warning',
      //         message: '时间未到,暂时无法考试!',
      //       })
      //     }
      //   }
      // }
    },
    // 在线直播
    lives() {
      getLives(this.livesQuery).then((res) => {
        // console.log(res, 2222)
        if (res.code == 200) {
          // this.liveList = res.rows

          this.total = res.total
          for (let i = 0; i < res.rows.length; i++) {
            // 直播状态
            if (res.rows[i].status != null) {
              getDicts({
                dictType: 'sys_live_status ',
                dictValue: res.rows[i].status,
              }).then((r) => {
                if (r.code == 200) {
                  this.$set(res.data.rows[i], 'zbzt', r.data)
                }
              })
            } else {
            }
            this.liveList = res.rows
            // 课程回顾总数
            this.kchgtotal = res.total
            // this.kchgtotal = this.liveList.filter((e) => e.isShow != 1).length
            // this.zxzbtotal = this.liveList.filter((e) => e.isShow == 1).length
          }
        }
      })
    },
    // 课程列表
    getCourse() {
      let userid = localStorage.getItem('userId')
      this.fcourseQuery.stuId = userid
      getCourseList(this.fcourseQuery).then((res) => {
        if (res.code == 200) {
          this.courseList = res.rows
          this.total = res.total
        }
      })
    },
    getInfoList() {
      let userid = localStorage.getItem('userId')
      // console.log('@@@@@', userid)

      getInfo(userid).then((res) => {
        if (res.code == 200) {
          localStorage.setItem('username', res.data.name)
          localStorage.setItem('isshowContent', JSON.stringify(this.navlist[0]))

          getDicts({
            dictType: 'stu_nation',
            dictValue: res.data.nation,
          }).then((r) => {
            if (r.code == 200) {
              this.$set(res.data, 'nationName', r.data)
              this.userinfo = res.data
              sessionStorage.userinfo = this.userinfo
              console.log('this.userinfo', this.userinfo)
            }
          })
        }
      })
    },
    passwording() {
      if (this.password == this.inputing) {
        this.$router.push({ name: 'live' })
      } else if (this.password != this.inputing) {
        this.dialogTableVisible = false
        this.$message({
          type: 'warning',
          message: '密码错误,请重新输入！',
        })
      }
    },
    // 打开输入密码框
    openpasswprd(item) {
      this.$prompt('请输入密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(({ value }) => {
          if (item.password == value) {
            this.playtype(item)
          } else {
            this.$message({
              type: 'warning',
              message: '请输入正确密码',
            })
          }
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '请输入正确密码',
          // })
        })
    },
    playtype(item) {
      if (item.windowType == 2) {
        // 则为id播放
        this.$router.push({
          name: 'live',
          query: { obj: item.idVarchar, stuId: this.userinfo.id },
        })
      } else if (item.windowType == 1) {
        // 则为livingCode播放
        sessionStorage.livepath = item.livingCode
        this.$router.push({ name: 'live' })
      }
      sessionStorage.btime = item.beginTime
    },

    //   直播
    liveNow(item) {
      sessionStorage.vidoesinfo = JSON.stringify(item)
      // console.log(item)
      if (item.usePassword == 1) {
        // 如果windowType==1则需要输入登录密码，验证成功后用idVarchar兑换播放凭证
        this.openpasswprd(item)
      } else if (item.usePassword == 2) {
        // 如果windowType==2则不需要输入登录密码
        this.playtype(item)
        // if (item.windowType == 2) {
        //   // 则为id播放
        //   this.$router.push({ name: 'live', query: { obj: item.idVarchar } })
        // } else if (item.windowType == 1) {
        //   // 则为livingCode播放
        //   sessionStorage.livepath = item.livingCode
        //   this.$router.push({ name: 'live' })
        // }
        // 如果为直播则直接进入直播页面
        // this.$router.push({ name: 'live', query: { path: item.livingCode } })
      }
      // console.log('进入直播页面', item)
      // if (item.zbzt != '已结束') {
      //   if (item.type == 0) {
      //     this.dialogTableVisible = true
      //     this.password = item.password
      //     this.pullUrl = item.pullUrl
      //   } else {
      //     this.$router.push({ name: 'live', params: { obj: item.pullUrl } })
      //   }
      // } else {
      //   this.$message({
      //     type: 'warning',
      //     message: '直播已结束！',
      //   })
      // }
    },
  },
  beforeDestroy() {},
  activated() {},
  deactivated() {},
}
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}
//导航背景色
.activebg {
  background: #e9eef3;

  > p {
    color: #666;
  }

  i.el-icon-arrow-right.el-icon-undefined {
    line-height: 50px;
    margin-right: 31px;
    color: #666;
  }
}

.noactivebg {
  background: #fff;

  > p {
    color: #666;
    font-size: 14px;
  }

  i.el-icon-arrow-right.el-icon-undefined {
    line-height: 50px;
    margin-right: 31px;
    color: #6d6d6d;
  }
}

.public_navmain {
  // width: 1200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8fafc;
  padding-bottom: 10px;
  min-height: calc(100vh - 60px - 112px);
  padding: 30px 0px;
  // border: 1px solid black;

  .public_navmain_left {
    width: 350px;
    // height: 100%;
    height: 500px;
    background: #f8fafc;
    box-sizing: border-box;

    .public_navmain_top {
      width: 100%;
      // height: 120px;
      // border: 1px solid black;
      padding: 10px;

      .suerbox {
        display: flex;
        height: 65px;
        align-items: center;
        justify-content: space-between;
        .userinfostuAvatar {
          width: 48px;
          height: 48px;
        }
        .userinfobox {
          font-size: 12px;
          color: #6d6d6d;
          flex: 1;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding-left: 10px;
        }
      }
    }

    .public_navmain_center {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #6d6d6d;
      margin-top: 20px;
      text-align: left;
      margin-left: 23px;
      margin-bottom: 35px;
    }

    .public_navmain_bottom {
      margin-top: 10px;
      margin-bottom: 10px;
      background: #f8fafc;

      > div {
        cursor: pointer;
        display: flex;
        justify-content: space-around;
        align-content: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6d6d6d;
        height: 50px;
        line-height: 50px;
        align-items: center;

        // > p {
        //   margin-left: 54px;
        // }

        // i.el-icon-arrow-right.el-icon-undefined {
        //   line-height: 50px;
        //   margin-right: 31px;
        // }
      }
    }
  }

  .public_navmain_right {
    width: 100%;
    box-sizing: border-box;
    margin-left: 5px;
    // height: 100%;
    min-height: 500px;
    text-align: left;

    .public_navmain_right_top {
      // border-bottom: 1px solid #e0e4e6;
      // width: 105px;

      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6d6d6d;
        // margin: 9px 0 19px;
        // margin-top: 20px;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }

    .public_navmain_right_bottom {
      width: 100%;
      min-height: calc(100% - 20px);
      // border: 1px solid black;
      .wdkc {
        // min-height: 500px;
        display: flex;
        flex-wrap: wrap;
        .wdkc_list {
          box-sizing: border-box;
          // border: 1px solid black;
          width: 300px;
          // padding: 10px;
          // height: 142px;
          background: #f8fafb;
          margin-bottom: 22px;
          cursor: pointer;

          .wdkc_list_top {
            width: 100%;
            // height: 45px;
            background: #f2f4f5;
            display: flex;
            align-items: center;
            padding: 0 20px;
            box-sizing: border-box;

            p {
              font-size: 13px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #8b8b8b;
              margin-right: 34px;
            }

            .status_class {
              width: 50px;
              /* height: 15px; */
              background: #1ab5ff;
              border-radius: 7px;
              padding: 5px;
              color: #ffffff;
              text-align: center;
            }
          }

          .wdkc_list_bottom {
            height: 97px;
            width: 100%;
            background: #f8fafb;
            // padding: 0 20px;
            box-sizing: border-box;
            display: flex;
            align-items: center;

            .wdkc_list_bottom_left {
              width: 50%;
              height: 65px;
              margin-right: 28px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .wdkc_list_bottom_right {
              p {
                font-size: 14px;
                font-family: Microsoft YaHei;
                text-align: left;
                color: #8b8b8b;
              }

              :nth-child(1) {
                font-weight: 600;
                color: #333333;
              }
            }
          }
        }
      }
    }

    .zxks {
      width: auto;
      overflow-y: scroll;
      overflow-x: hidden;
      box-sizing: border-box;
      &::-webkit-scrollbar {
        display: none;
      }
      padding: 0px 5px;
      .zxks_list {
        margin: 15px 0;
        padding: 25px 25px 10px 25px;
        box-sizing: border-box;
        width: 100%;
        // height: 116px;
        background: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        box-sizing: border-box;
        .zxks_top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 14px;
          img {
            width: 18px;
            height: 19px;
            margin-right: 8px;
          }
          .tit {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #6d6d6d;
          }
        }

        .zxks_bottom {
          display: flex;
          justify-content: space-between;
          padding-left: 2em;
          margin-bottom: 10px;
          font-size: 12px;
          > span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #6d6d6d;
          }

          p {
            // font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #f67e77;
          }
        }
      }
    }

    .grxx {
      .grxx_tag {
        display: flex;
        margin: 27px 0;

        .grxx_name {
          width: 100px;
          text-align: center;
          height: 55px;
          line-height: 55px;
          // background: #f6f8f9;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #6d6d6d;
          // margin-right: 30px;
        }

        .grxx_main {
          width: 100%;
          border-bottom: 1px solid #e0e4e6;
          display: flex;
          align-items: center;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #6d6d6d;
        }
      }
    }

    .zxxz {
      .zxks_list {
        //padding: 39px 35px 21px 35px;
        //box-sizing: border-box;
        .zxks_top {
          .zxxz_con {
            display: flex;
            align-items: center;
          }

          .sczs {
            width: 220px;
            height: 31px;
            line-height: 31px;
            border: 1px solid #1ab5ff;
            border-radius: 2px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #1ab5ff;
            text-align: center;
            margin-left: 60px;
          }
        }
      }
    }
  }
}
.zxzbbox {
  height: 680px;
  overflow: scroll;
  // min-height: 500px;
  // border: 1px solid black;
  // overflow-y: scroll;
  overflow-x: hidden;
  // 去除滚动条显示
}
.zxzbbox::-webkit-scrollbar {
  display: none;
}
.exambox {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #6d6d6d;
  // margin: 15px 0px;
}
.livebox {
  // margin-bottom: 40px;
  // width: 33%;
  // box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  width: 25%;
  box-sizing: border-box;
  height: 320px;
  cursor: pointer;
  padding: 10px;
  // border: 1px solid black;

  .innerbox {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
    padding: 10px;
    border-radius: 12px;
    height: 300px;
    // border: 1px solid black;
    justify-content: space-between;
    img {
      width: 100%;
      height: 160px;
      // margin-right: 10px;
      border-radius: 12px;
      //  border: 1px solid black;
    }
    .innerboxfoot {
      display: flex;
      flex-direction: column;
      // justify-content: space-around;
      height: 110px;
      box-sizing: border-box;
      // border: 1px solid black;
    }
  }
}
.box {
  background-color: #fff;
  // height: 100%;
  border-radius: 12px;
  padding: 15px;
  // box-shadow: 0px 0px 0px 2px black;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}
.jianjie {
  height: 100px;
  font-size: 12px;
  width: 280px; //宽度

  overflow: hidden; //超出文本隐藏

  text-overflow: ellipsis; ///超出部分省略号显示

  display: -webkit-box; //弹性盒模型

  -webkit-box-orient: vertical; //上下垂直

  -webkit-line-clamp: 4; //自定义行数
}

.classTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}
.classPerson {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 12px;
  color: #667280;
  line-height: 14px;
}
.classTime {
  background: #edeff2;
  border-radius: 4px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 12px;
  color: #667280;
  line-height: 14px;
  padding: 3px 5px;
}
.classIntor {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 12px;
  color: #a1a9b2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* 设置宽度和高度 */
  // height: 40px;
}
.boxcenter {
  width: 1200px;
  // border: 1px solid black;
  display: flex;
  // min-height: 85%;
  min-height: 700px;
}
.el-pagination {
  text-align: right;
}
.el-divider--horizontal {
  margin: 0px;
}
::v-deep.studybox {
  height: 20%;
  width: 100%;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  // border: 1px solid black;

  padding: 20px;
  .studyimg {
    width: 250px;
    height: 200px;
    // border: 1px solid black;
    border-radius: 10px;
  }
  .studycontent {
    width: 45%;
    height: 100px;
    // border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0px 20px;
    .el-form-item {
      margin-bottom: 10px;
      .el-form-item__label {
        line-height: 20px;
        width: auto !important;
      }
      .el-form-item__content {
        line-height: 20px;
        margin-left: 0px !important;
        color: #606266;
      }
    }
  }
  .studychapter {
    width: 100px;
    height: 100px;
    // border: 1px solid black;
  }
  .studytutton {
    width: 140px;
    height: 100px;
    // border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    .gostudy {
      width: 80px;
      height: 30px;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      padding: 0px;
    }
  }
}
.prseetext {
  width: 40px;
  font-size: 14px;
  color: #606266;
}
.el-progress__text {
  margin-left: 70px;
}
.classtitle {
  font-size: 16px;
}
.companyName {
  overflow: hidden;
  font-size: 12px;
  // width: 280px; //宽度
  overflow: hidden; //超出文本隐藏
  text-overflow: ellipsis; ///超出部分省略号显示
  display: -webkit-box; //弹性盒模型
  -webkit-box-orient: vertical; //上下垂直
  -webkit-line-clamp: 1; //自定义行数
  margin-top: 5px;
  cursor: pointer;
}
.block {
  margin-top: 25px;
}
</style>
